import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './PrincipalsHeader.css'

export default () => {
  const data = useStaticQuery(graphql`
    query principalsQuery {
      file(relativePath: {eq: "Principals_images/statementoffaith2.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2000, quality: 100){
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
  return (
    <div className='principalsHeader_Container'>
      <BackgroundImage fluid={data.file.childImageSharp.fluid}
        className='principalHeader_Background'>
        <div className='principalsHeader_Title'>
          <h1>本 教 會 信 條</h1>
        </div>
      </BackgroundImage>
    </div>
  )
}