import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import PrincipalsHeader from '../components/Header/PrincipalsHeader'
import PrincipalsContent from '../components/PincipalsContent/PrincipalsContent'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <PrincipalsHeader />
        </div>
        <div>
          <PrincipalsContent />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }