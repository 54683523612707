import React from 'react'
import { Col, Row } from 'antd'
import './PrincipalsContent.css'

export default () => {
    return (
        <div>
            <div className='principalsTitleBackground'>
                <div className='principalsTitle'>
                    <h1>高 舉 基 督， 傳 講 及 教 導 神 的 話 語， 廣 傳 福 音， 裝 備 信 徒， 建 立 基 督 的 身 體， 來 榮 耀 我 們 所 事 奉 的 獨 一 真 神。</h1>
                </div>
            </div>
            <div className='principalsContentBackground'>
                <div className='principalsContent'>
                    <p>1. 獨一的真神，為創造掌管天地萬物及人類的主宰，是自有永有父、子、聖靈三位一體的神。他也是我們的父。</p>
                    <p>2. 耶穌基督是神的兒子，道成肉身，藉著童貞女馬利亞所生，為要拯救世人，脫離罪惡的捆綁與審判，釘死在十字架上，埋葬了，第三天覆活，升天，且要再來。他也是我們的生命。 </p>
                    <p>3. 聖靈與聖父、聖子同尊同榮。他是保惠師。他來使人知罪重生，住在信徒心中，指教引領，並為信徒代求，添增屬靈的能力，使我們事奉神，服侍人。 </p>
                    <p>4. 全部新舊約聖經，都是神所默示的，絕無錯誤，為信徒信仰和生活最高神聖的準則。 </p>
                    <p>5. 世人是照神的形像造的，在亞當裏墮落成為罪人，都需要神的救恩。唯有相信接受耶穌在十字架上的救贖，罪才能得赦免，並靠聖靈重生，與神和好，成為神的兒女， 敬虔度日，過聖潔公義的生活。</p>
                    <p>6. 教會是由相信主耶穌基督重生得救的信徒所組成，連於元首基督，在聖靈裏合為一體。共同敬拜、交通、事奉和傳福音。</p>
                    <p>7. 洗禮是表征我們重生的事實，聖餐是紀念主耶穌基督為我們的罪受死的大愛，為教會應遵守的聖禮，也是信徒在神和人面前應有的見證。 </p>
                    <p>8. 主耶穌基督必親自再來，審判全人類。死人都要覆活，信主的覆活與主同享永生，不信的覆活受永刑。</p>
                </div>
            </div>
            <div className='principalsTitleBackground'>
                <div className='principalsTitle'>
                    <h1>教會修正會章 - <small>關於婚姻及男女性關系的宣言</small></h1>
                </div>
            </div>
            <div className='principalsContentBackground'>
                <div className='principalsContent'>
                    <p>我們中華福音教會相信「婚姻」只有一個解釋， 那就是按照聖經的記載，神命定一男一女結合成為獨有的一體。</p>
                    <p>我們相信神認可的性關系只能發生在已經有婚姻關系的一男一女。我們相信神明確命令在已婚的一男一女以外不可有其他的親密性關系。我們並且相信任何不倫 的性關系是有罪且冒瀆神的。例如奸淫，私通，同性戀行為，雙性兼戀行為，人獸交合，亂倫，娼妓，或改變性向等都是有罪冒犯神的。</p>
                    <p>我們相信神呼召我們要聖潔，並要敬畏他。所以中華福音教會現有的神職人員，會員，及一切設備都必須順從聖經的原則事奉神。即使遭遇政府的幹預，也不能用於違背神，不合聖經的婚禮。</p>
                    <p>我們相信因耶穌基督的恩慈及憐憫， 凡認罪悔改的人，神都會賜給恕罪悔改的機會。我們相信每一個人都須給於同情，友愛，仁慈，尊重及自尊。仇恨及搔擾的行為及態度都是違背聖經及教會的信仰而不能接受的。</p>
                    <p>中華福音教會長老會議通過於2015年八月十八日</p>
                </div>
            </div>
        </div>
    )
}